@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .socialIcon {
    @apply w-11 border-[1px] border-blue-400 border-solid h-11 rounded-full px-2 py-1 text-pink-500  hover:text-blue-400 cursor-pointer transition duration-200 hover:animate-pulse;
  }
}

.slick-slide > div { 
  margin : 0 10px;
}